import { http } from '@/lib/http'

export default {
  /**
   * 币种汇率转换
   * @param {Object} params
   * @param {string[]} [params.symbols] 币种
   * @param {string} [params.datetime] 时间
   * @param {string} [params.timezone] 时区
   */
  getCurrencyRate: (params: object) => {
    return http.request('get', `/price/coin`, { params })
  },
  /**
   * 币种计算价格
   * @param {Object} data
   * @param {string} data.entityId 项目id
   * @param {string} data.currency 币种
   * @param {string} data.datetimeStr 时间
   * @param {string} data.timezone 时区
   * @param {string} data.amount 数量
   * @param {string} data.quoteCurrency 报价币种
   */
  getCurrencyPriceCalculate: (data: object) => {
    return http.request('post', `/price/calculate`, { data })
  }
}
